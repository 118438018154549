import 'bootstrap';
import { Splide } from '@splidejs/splide';

import '../scss/main.scss';

// Splide slider

var userLang = navigator.language || navigator.userLanguage;

///
// Hero banner slider
///

var splide;
var previousButton, nextButton;
var rotationButton, pauseContainer, resumeContainer;

document.addEventListener('DOMContentLoaded', function() {
    // previousButton = document.querySelector('.block-stage .previous-button');
    // nextButton = document.querySelector('.block-stage .next-button');

    rotationButton = document.querySelector('.block-stage .rotation-button');
    pauseContainer = document.querySelector('.block-stage .rotation-button .pause-container');
    resumeContainer = document.querySelector('.block-stage .rotation-button .resume-container');

    var prevLabel = 'Previous Image';
    var nextLabel = 'Next Image';

    // Check if the user's language is German
    if (userLang === 'de' || userLang.startsWith('de')) {
        prevLabel = 'Vorheriges Bild';
        nextLabel = 'Nächstes Bild';
    }

    splide = new Splide('.splide', {
        autoplay: true,
        interval: 5300,
        pauseOnHover: true,
        arrows: true,
        perPage: 1,
        type: 'loop',
        pagination: false,
        arrowPath: 'M8.333 28.458l2 1.75 12.792-14.208-0.792-0.875-12-13.333-2 1.75 11.208 12.458z',
        i18n: {
            prev: prevLabel,
            next: nextLabel,
        },
        keyboard: false,  // Splide listens to key events at the document level and moves ALL carousels when arrow keys are used. Since keyboard and screen reader users use these keys to operate the tabs, this creates a very unusual experience.
        slideFocus: false  // removes tabindex="0" from each slide wrapper, since we only want our links inside each slide to receive focus.
    }).mount();

    // To prevent animation issues, let's make every slide visible before a transition happens. Splide will then automatically remove the `.is-visible` class from non-visible slides once the transition is finished.
    splide.on('move', function() {
        var slides = document.querySelectorAll('.splide .splide__slide');

        slides.forEach(function(slide) {
            slide.classList.add('is-visible');
        });
    });

    // Toggle autoplay when the pause/resume button is activated
    rotationButton.addEventListener('click', function(e) {
        toggleAutoplay();
    });
});


/**
 Disable or enable built-in Splide Slider autoplay
 */
function toggleAutoplay() {
    var autoplayEnabled = splide.options.autoplay;

    if(autoplayEnabled) {
        disableAutoplay();
    } else {
        enableAutoplay();
    }
}

function disableAutoplay() {
    // Disable autoplay using the Splide API
    splide.Components.Autoplay.pause();
    splide.options = { autoplay: false };

    // Switch the rotation button icon to "resume"
    pauseContainer.classList.remove('is-visible');
    resumeContainer.classList.add('is-visible');
}

function enableAutoplay() {
    // Disable autoplay using the Splide API
    splide.Components.Autoplay.play();
    splide.options = {
        autoplay: true
    };

    // Switch the rotation button icon to "pause"
    pauseContainer.classList.add('is-visible');
    resumeContainer.classList.remove('is-visible');
}


jQuery(document).ready(function ($) {
    $(function() {
        $('.mejs-overlay-loading').closest('.mejs-overlay').addClass('load'); //just a helper class

        var $video = $('div.video video');
        var vidWidth = $video.attr('width');
        var vidHeight = $video.attr('height');

        $(window).resize(function() {
            var targetWidth = $(this).width(); //using window width here will proportion the video to be full screen; adjust as needed
            $('div.video, div.video .mejs-container').css('height', Math.ceil(vidHeight * (targetWidth / vidWidth)));
        }).resize();
    });
});


document.addEventListener("DOMContentLoaded", function () {
    var scrollButtons = document.querySelectorAll(".button-scroll-down");

    scrollButtons.forEach(function (button) {
        button.addEventListener("click", function () {
            var currentBlock = button.closest(".block");
            var nextBlock = currentBlock.nextElementSibling;

            if (nextBlock !== null && nextBlock.classList.contains("block")) {
                var offsetTop = nextBlock.offsetTop;
                window.scrollTo({
                    top: offsetTop,
                    behavior: "smooth"
                });
            }
            return false;
        });
    });
});

